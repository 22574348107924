<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
  >
    <h5 class="text-center"><strong>{{ $t('message.two_factor_authentication_title') }}</strong></h5>

    <br>

    <p class="text-sm-body-1">{{ $t('message.two_factor_authentication_introduction') }}</p>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-alert
          text
          dense
          color="teal"
          border="left"
          type="success"
          v-if="successMessage"
        >
          {{ successMessage }}
        </v-alert>

        <v-text-field
          autocomplete="current-password"
          v-model="authCode"
          :label="$t('message.two_factor_authentication_access_code')"
        ></v-text-field>

        <v-alert
          text
          dense
          type="error"
          v-if="error !== null"
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <router-link to="/qr-code-perdu" class="recovery">{{ $t('message.two_factor_authentication_reset') }}</router-link>
      <router-link to="/connexion" class="recovery mt-2 text-left">{{ $t('message.back_to_login') }}</router-link>
      <br>
      <br>
    </v-row>

    <button type="submit" class="btn btn-connect notranslate"><strong>{{ $t('message.category_login') }}</strong></button>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'

export default {
  name: 'TwoFactorLogin',
  data: () => ({
    valid: false,
    authCode: '',
    authCodeRules: [
      v => !!v || this.$t('message.mandatory_two_factor_authentication_code')
    ],
    successMessage: null,
  }),
  created () {
    this.$emit('update:layout', LoginLayout)

    if (this.$route.query.forgot === 'true') {
      this.successMessage = this.$t('message.password_reset_instructions');
    }

    document.title = config.title + ' - ' + this.$t('message.two_factor_authentication_title')
  },
  computed: {
    error () {
      return this.$store.getters.getError
    },
    email () {
      return this.$store.getters.getEmail
    },
    tempToken () {
      return this.$store.getters.getTempToken
    }
  },
  methods: {
    checkForm () {
      if (this.valid) {
        this.twoFactorLogin()
      }
    },
    twoFactorLogin () {
      const data = {
        email: this.email.toLowerCase(),
        authCode: this.authCode,
        tempToken: this.tempToken
      }

      this.$store.dispatch('twoFactorLogin', data)
    }
  }
}
</script>
